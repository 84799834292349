import Messenger from 'components/core/messenger'
import RouterChange from 'components/core/router-change'

export default function Core() {
  return (
    <div>
      <Messenger />
      <RouterChange />
    </div>
  )
}
