const template = `
  query User($userId: ID!) {
    user(id: $userId) {
      id
      name
      avatar
      stylist {
        show
        studio {
          address
        }
      }
    }
  }
`

export default async function userInfo({ userId }) {
  const { user } = await request(template, { userId })
  return user
}
