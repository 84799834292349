import useFetchChatrooms from './action/fetch-chatrooms'
import chatroomInfoUpdate from './action/chatroom-info-update'
import chatroomRead from './action/chatroom-read'
import useChatroomInit from './action/chatroom-init'
import talkToUser from './action/talk-to-user'

let openByRouterQuery = true

export default function useStoreState() {
  const { state, mutate } = useStatic({
    key: uuid(),
    totalUnread: 0,
    showUserId: false,
    rooms: [],
    roomDic: {},
    isChatroomFinish: false,
    isChatroomFetching: false,
    panelVisible: false,
    active: null
  })

  const panel = usePanel()
  const router = useRouter()
  const { user, fromApp, loading } = useCurrent()
  const zIndex = panel.zIndexResolver(state.key, state.panelVisible)

  const checkRouterQuery = () => {
    if (!user) return
    if (!store.plugin.firebaseSignIn) return setTimeout(checkRouterQuery, 200)
    const userId = parseInt(router.query.talkto)
    $messenger.talkToUser(userId)
  }

  useEffect(() => {
    if (!openByRouterQuery) return
    if (!user) return
    openByRouterQuery = false
    const userId = parseInt(router.query.talkto)
    if (!userId) return
    checkRouterQuery()
  }, [user])

  const showBtn = () => {
    if (fromApp) return false
    if (router.pathname.includes('/studio/manage')) return false
    return true
  }
  const show = showBtn()
  const togglePanel = () => {
    if (loading) return
    if (!user) return panel.open('auth')
    if (state.panelVisible) setActive(null)
    if (!state.panelVisible) mkt.chatRoomOpen()
    mutate({ panelVisible: !state.panelVisible })
  }

  const closePanel = () => {
    if (!state.panelVisible) return
    togglePanel()
  }

  const chatroomInit = useChatroomInit({ state, mutate })

  const fetchChatrooms = useFetchChatrooms({
    state,
    chatroomInit,
    mutate
  })

  const init = () => {
    if (!store.plugin.firebase) return setTimeout(init, 100)
    state.rooms = []
    state.roomDic = {}
    state.isChatroomFinish = false
    state.active = null
    mutate()
    fetchChatrooms()
  }

  const fetchRoomId = mateId => [mateId, user.id].sortBy(v => -v).join('_')

  const setActive = active => {
    if (active?.user.id === '0') return $alert('查看線上預約功能僅限 StyleMap App 使用', 'error')
    state.active?.off()
    state.active = active
    if (!active) return mutate()
    active.on()
  }

  const context = {
    fetchRoomId,
    chatroomInit,
    setActive,
    state,
    mutate,
    user
  }

  s.ChatroomInfo = record => chatroomInfoUpdate({
    ...context,
    record
  })

  s.ChatroomRead = ({ totalUnread, chatmateId }) => {
    chatroomRead({
      ...context,
      totalUnread,
      chatmateId
    })
  }

  s.ChatroomMateRead = ({ chatmateId }) => {
    const id = fetchRoomId(chatmateId)
    const room = state.roomDic[id]
    if (!room) return
    room.mateRead()
  }

  s.ChatroomMateUnSendMessage = ({ chatmateId, messageKey }) => {
    const id = fetchRoomId(chatmateId)
    const room = state.roomDic[id]
    if (!room) return
    room.mateUnSendMessage({ key: messageKey })
  }

  global.$messenger = {
    init,
    talkToUser: (userId, autoMessage) => {
      if (!user) return panel.open('auth')
      mkt.messageOpen(userId)
      talkToUser({
        ...context,
        autoMessage,
        userId
      })
    }
  }

  return {
    state,
    zIndex,
    show,
    togglePanel,
    closePanel,
    setActive,
    fetchChatrooms
  }
}
