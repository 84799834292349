import MainHeader from './main-header'
import MainSelectUser from './main-select-user'
import MainMessage from './main-message'

export default function Main() {
  const { active } = useCtx('coreMessenger')
  const renderComponent = () => {
    if (active) return <MainMessage/>
    return <MainSelectUser/>
  }
  return (
    <div className='w-full h-full'>
      { renderComponent() }
      <MainHeader />
    </div>
  )
}
