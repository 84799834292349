const template = `
  query Metadata($url: String!) {
    metadata(url: $url) {
      title
      image
      url
      description
    }
  }
`

export default async function metadata({ url }) {
  const { metadata } = await request(template, { url })
  return metadata
}
