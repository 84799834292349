import visitorLogCreate from 'request/common/visitor-log-create'

export default function RouterChange() {
  const router = useRouter()
  const path = router.url

  useEffect(() => {
    pageView()
    if (!store.autoClose.skipOnce) {
      store.autoClose.closePanels()
    }
    store.autoClose.skipOnce = false
  }, [path])

  return ''
}

const pageView = () => {
  const path = '/' + window.location.href.split('/').slice(3).join('/')
  visitorLogCreate({ path })
  if (!process.env.NEXT_PUBLIC_FB_PIXEL_ID) return

  gtag('event', 'page_view', { page_path: path })
  fbq('track', 'PageView')
}
