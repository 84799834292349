import Main from './main'
export default function DesktopPanel() {
  const { panelVisible, zIndex } = useCtx('coreMessenger')
  const style = () => {
    if (panelVisible) return 'w-[25rem] max-h-[38rem] h-[90%] opacity-100'
    return 'h-0 w-0 opacity-0'
  }
  return (
    <div className={ cn('fixed rounded-xl right-10 bottom-10 bg-white hidden sm:block transition-all shadow-xl overflow-hidden', style()) }
      style={ { zIndex } }
    >
      <Main />
    </div>
  )
}
