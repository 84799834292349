import Main from './main'
export default function MobilePanel() {
  const { closePanel, panelVisible } = useCtx('coreMessenger')

  return (
    <div className='sm:hidden'>
      <C.Modal visible={ panelVisible }
        close={ closePanel }
        noCloseBtn
        noPadding
      >
        <div className='relative'>
          <Main />
        </div>
      </C.Modal>
    </div>
  )
}
