import { Context, useStore } from 'store/core-messenger'
import BackgroundCover from './background-cover'
import FloatButton from './float-button'
import DesktopPanel from './desktop-panel'
import MobilePanel from './mobile-panel'

export default function Messenger() {
  return (
    <Context.Provider value={ useStore() }>
      <BackgroundCover />
      <DesktopPanel />
      <FloatButton />
      <MobilePanel />
    </Context.Provider>
  )
}
