import addMessages from '../action/add-messages'
import fetchMessages from '../action/fetch-messages'
import sendMessage from '../action/send-message'
import unSendMessage from '../action/un-send-message'
import uploadImages from '../action/upload-images'
import chatroomRead from 'request/core-messenger/chatroom-read'
import syncMessages from '../action/sync-messages'
import { animateToBottom } from '../action/scroll-messages-bar'
import messageInit from './message'
import fetchUserInfo from 'request/core-messenger/user-info'

export default function formatter({ record, user, state, mutate, t }) {
  const mate = record.chatmateUser
  const id = [mate.id, user.id].sortBy(v => -v).join('_')

  if (state.roomDic[id]) return state.roomDic[id]

  const { lastMessage, lastByOwner, lastUpdatedAt } = record
  const ref = store.plugin.firebase.db.ref(`/Messages/${id}`)
  const typingRef = store.plugin.firebase.db.ref(`/Channels/${id}/typingIndicator`)
  const current = {
    id,
    ref,
    typingRef,
    lastUpdatedAt: dayjs(lastUpdatedAt),
    lastMessage,
    lastByOwner,
    unReadCount: record.unread,
    lastReadMessageId: null,
    isMessageFetching: false,
    isMessageFinish: false,
    mateNewText: '',
    messageDic: {},
    sendingDic: {},
    autoMessage: null,
    typingTimeoutId: null,
    typingDic: {},
    user: {
      id: mate.id,
      isServiceAccount: parseInt(mate.id) === 1,
      name: mate.name,
      avatar: mate.avatar,
      stylist: undefined,
      studio: undefined
    },
    messages: []
  }
  current.reset = () => {
    current.messages = []
    current.isMessageFinish = false
    current.messageDic = {}
    current.sendingDic = {}
  }
  current.fetchable = () => {
    if (current.isMessageFetching) return false
    if (current.isMessageFinish) return false
    return true
  }
  current.loadUserInfo = async() => {
    if (current.user.stylist !== undefined) return
    const userId = mate.id
    const { stylist } = await fetchUserInfo({ userId })
    current.user.stylist = stylist
    current.user.studio = stylist?.studio
  }
  current.on = async() => {
    await current.loadUserInfo()
    current.reset()
    current.setTyping(false)
    ref.off()
    typingRef.off()
    typingRef.onDisconnect().update({ [user.id]: false })
    let skipFirstTime = true
    syncMessages({
      user,
      mutate,
      active: current
    })
    typingRef
      .on('value', snapshot => {
        const newValue = snapshot.val() || {}
        if (!!newValue[mate.id] === !!current.typingDic[mate.id]) return
        current.typingDic = newValue
        mutate()
      })
    ref.limitToLast(1)
      .on('value', snapshot => {
        if (skipFirstTime) {
          skipFirstTime = false
          return
        }
        const dic = snapshot.val()
        const key = Object.keys(dic)[0]
        if (current.messageDic[key]) return
        current.addMessages({ snapshot: { val() { return dic } } })
        mutate()
      })
    current.read()
  }
  current.isMateTyping = () => current.typingDic[mate.id]
  current.off = () => {
    current.setTyping(false)
    ref.off()
    typingRef.off()
  }
  current.read = () => chatroomRead({ chatmateId: mate.id })

  current.scrollAtBottom = () => {
    if (!current.mateNewText) return
    current.mateNewText = ''
    mutate()
  }
  current.toMateNewText = () => {
    current.mateNewText = ''
    animateToBottom()
    mutate()
  }
  current.addMessages = ({ snapshot, reverse }) => {
    addMessages({
      snapshot,
      reverse,
      active: current
    })
  }
  current.messageInit = ({ record, key }) => messageInit({
    active: current,
    record,
    key,
    user,
    t
  })
  current.setTyping = value => {
    if (value) {
      clearTimeout(current.typingTimeoutId)
      current.typingTimeoutId = setTimeout(() => {
        current.setTyping(false)
      }, 1000)
    }
    current.typingRef.update({ [user.id]: value })
  }
  current.mateRead = () => {
    const lastMessage = current.messages.at(-1)
    if (!lastMessage) return
    current.lastReadMessageId = lastMessage.id
    mutate()
  }
  current.mateUnSendMessage = ({ key }) => {
    const message = current.messages.find(m => m.id === key)
    if (!message) return
    message.unSend = true
    mutate()
  }
  current.fetchMessages = () => fetchMessages({
    mutate,
    active: current
  })
  current.uploadImages = files => uploadImages({
    files,
    user,
    mutate,
    active: current
  })
  current.sendMessage = ({ message, ...params }) => {
    sendMessage({
      message,
      params,
      user,
      active: current,
      mutate
    })
  }
  current.unSendMessage = ({ message, ...params }) => {
    unSendMessage({
      message,
      params,
      user,
      active: current,
      mutate,
      t
    })
  }
  current.toUserPage = () => {
    if (!current.user.stylist?.show) return
    h.path.go.stylist({ id: current.user.id })
  }
  state.roomDic[id] = current

  return current
}
