import formatter from '../formatter/chatroom'

export default function useChatroomInit({ state, mutate }) {
  const { user } = useCurrent()
  const t = useI18n()
  return ({ record }) => formatter({
    record,
    user,
    state,
    t,
    mutate
  })
}
