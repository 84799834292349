import fetchUserInfo from 'request/core-messenger/user-info'

export default async function talkToUser({ userId, autoMessage, user, state, mutate, fetchRoomId, setActive, chatroomInit }) {
  const id = fetchRoomId(userId)
  const fetchRoom = async() => {
    const room = state.roomDic[id]
    if (room) return room
    const chatmateUser = await fetchUserInfo({ userId })
    const newRoom = chatroomInit({
      record: {
        chatmateUser,
        lastUpdatedAt: dayjs()
      }
    })

    const setLastMessage = async() => {
      const snapshot = await newRoom.ref
        .orderByKey()
        .limitToLast(1)
        .get()
      const dic = snapshot.val()
      for (const key in dic) {
        const { message, senderId } = dic[key]
        newRoom.lastMessage = message
        newRoom.lastByOwner = senderId === user.id
        return
      }
    }

    setLastMessage()

    state.rooms = [
      newRoom,
      ...state.rooms
    ]
    return newRoom
  }

  const active = await fetchRoom()
  active.autoMessage = autoMessage

  mutate({ panelVisible: true })
  setActive(active)
}
