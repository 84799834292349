import groupMessageInteraction from 'request/core-messenger/group-message-interaction'

const maxImageWidth = 12
const fetchImageStyle = ({ width, height }) => ({
  width: maxImageWidth + 'rem',
  height: height * maxImageWidth / width + 'rem'
})

const imageUrlCache = {}

export default function formatter({ active, record, key, user, t }) {
  const { isPreview } = record

  const typeInfo = () => {
    if (isPreview) {
      return {
        type: 'preview',
        url: record.string,
        width: record.width,
        height: record.height,
        style: fetchImageStyle(record),
        isPhoto: true,
        percentage: 0
      }
    }
    if (record.photoURL) {
      return {
        type: 'photo',
        isPhoto: true,
        message: t('common:messenger.typeText.photo'),
        setStyle: ({ width, height }) => {
          message.style = fetchImageStyle({ width, height })
        },
        style: fetchImageStyle(record)
      }
    }
    return { type: 'text' }
  }
  const message = {
    id: key,
    message: record.message || '',
    sendAt: dayjs.unix(record.timeStamp),
    senderId: record.senderId,
    isPreview: record.isPreview,
    ...record,
    ...typeInfo()
  }

  const isSender = user.id === record.senderId
  const isSending = () => {
    if (isPreview) return true
    active.sendingDic[key]
  }
  const isRead = () => {
    if (isPreview) return false
    if (record.isRead) return true
    return active.lastReadMessageId >= key
  }
  const readMessage = () => {
    if (isPreview) return
    if (isSender) return
    if (record.isRead) return

    active.ref
      .child(key)
      .update({ isRead: true })

    if (!(record.retarget_id > 0)) return
    groupMessageInteraction({ id: record.retarget_id, action: 'read' })
  }

  const setLastReadMessageId = () => {
    if (isPreview) return
    if (isSender) return
    if (!record.isRead) return
    if (active.lastReadMessageId > key) return
    active.lastReadMessageId = key
  }

  const fetchImage = async() => {
    if (message.type !== 'photo') return
    const key = message.photoURL
    message.imageSrc = imageUrlCache[key]
    if (message.imageSrc) return
    const url = await store.plugin.firebase
      .storage()
      .refFromURL(key)
      .getDownloadURL()
    imageUrlCache[key] = url
    message.imageSrc = url
  }

  readMessage()
  setLastReadMessageId()
  fetchImage()

  if (message.booking_id) {
    const renderText = () => {
      if (message.booking_state === 2) return t('common:messenger.button.consumerDetails')
      return t('common:messenger.button.appointmentDetail')
    }

    message.bookingInfo = {
      openBooking: () => h.openUserBooking(message.booking_id),
      text: renderText()
    }
  }

  if (message.booking_state === 1) {
    message.showHotToPayBtn = true
  }

  message.anyButton = !!message.booking_state

  message.isSender = isSender
  message.isRead = isRead
  message.isSending = isSending

  message.click = () => {
    if (isSender) return
    if (!(record.retarget_id > 0)) return
    groupMessageInteraction({ id: record.retarget_id, action: 'click' })
  }

  return message
}
