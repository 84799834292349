import addMessage from './add-messages'
import { toScrollBottom } from './scroll-messages-bar'

export default async function syncMessages({ active, mutate, user }) {
  const last = active.messages.at(-1)
  if (!last){
    active.fetchMessages()
    return
  }
  const snapshot = await active.ref.orderByKey()
    .startAt(last.id)
    .get()

  addMessage({
    snapshot,
    active,
    user
  })

  mutate()
  toScrollBottom()
}
