export default function FloatButton() {
  const { togglePanel, panelVisible, show, totalUnread } = useCtx('coreMessenger')

  if (!show) return ''

  const scale = () => {
    if (panelVisible) return 'w-0 h-0 scale-0'
    return 'w-14 h-14 scale-100'
  }

  return (
    <div className='fixed right-12 bottom-20 sm:right-20 sm:bottom-20 translate-x-1/2 translate-y-1/2 z-40'>
      <div className={ cn('relative rounded-full text-black bg-white hover:bg-black hover:text-white cursor-pointer transition-all border opacity-95 shadow hover:border-black', scale()) }
        onClick={ togglePanel }
      >
        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
          <Icon.Message className='scale-125'/>
          <div className='absolute top-[-0.7rem] left-[2.0rem] -translate-x-1/2 -translate-y-1/2'>
            <C.Badge count={ totalUnread } />
          </div>
        </div>
      </div>
    </div>
  )
}
