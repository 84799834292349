import { toScrollBottom, nearScrollBottom } from '../action/scroll-messages-bar'

export default function addMessages({ snapshot, active, reverse }) {
  const dic = snapshot.val()
  let messages = []

  for (const key in dic) {
    if (active.messageDic[key]) continue
    active.messageDic[key] = true
    const message = active.messageInit({
      record: dic[key],
      key
    })
    if (active.isSending) {
      active.sendingDic[key] = true
    }
    messages.push(message)
  }

  if (reverse) {
    active.messages = [
      ...messages,
      ...active.messages
    ]
    active.isMessageFinish = !messages.length
    return
  }

  if (!messages.length) return
  active.messages = [
    ...active.messages,
    ...messages
  ]
  const last = messages.at(-1)
  if (last.isSender){
    toScrollBottom()
    h.audio.play('messageSent')
    return
  }

  h.audio.play('messageReceived')
  if (nearScrollBottom()) {
    toScrollBottom()
    return
  }
  active.mateNewText = last.message
}
