export default function Photo({ message }) {
  const { state, mutate } = useStatic({
    key: uuid(),
    src: message.imageSrc,
    style: null
  })

  state.onLoad ||= ({ width, height }) => {
    if (state.style) return
    message.setStyle({ width, height })
    state.style = message.style
    mutate()
  }

  useEffect(() => {
    if (message.imageSrc) return
    const interval = setInterval(() => {
      if (!message.imageSrc) return
      clearInterval(interval)
      state.src = message.imageSrc
      mutate()
    }, 100)
    return () => clearInterval(interval)
  }, [])

  const renderImage = () => {
    if (!state.src) return <C.Loading className='opacity-20' />
    return (
      <C.Image
        src={ state.src }
        className='w-full h-full cursor-pointer'
        downloadable
        onLoad={ state.onLoad }
        onClick={ () => {
          h.$singlePhoto(state.src)
          message.click()
        } }
      />
    )
  }
  return (
    <div
      style={ state.style || message.style }
      className='bg-gray-50 shadow flex items-center justify-center rounded-[1rem] overflow-hidden border border-gray-50'
    >
      { renderImage() }
    </div>
  )

}
