import fetchChatrooms from 'request/core-messenger/chatrooms'

export default function useFetchChatrooms({ state, mutate, chatroomInit }) {
  const { isAdmin } = useCurrent()
  const limit = 20

  return async() => {
    if (state.isChatroomFinish) return
    if (state.isChatroomFetching) return
    mutate({ isChatroomFetching: true })

    const offset = state.rooms.length
    const { chatrooms, totalUnread } = await fetchChatrooms({ limit, offset })
    state.totalUnread = totalUnread
    state.showUserId = isAdmin

    const rooms = chatrooms
      .map(record => chatroomInit({ record }))
      .filter(room => !!room)

    const isChatroomFinish = chatrooms.length < limit

    mutate({
      isChatroomFetching: false,
      isChatroomFinish,
      rooms: [
        ...state.rooms,
        ...rooms
      ]
    })
  }
}
