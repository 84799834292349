import { stopAtCurrentScroll, toScrollBottom } from './scroll-messages-bar'

const limit = 20

export default async function fetchMessages({ active, mutate }) {
  if (!active.fetchable()) return
  active.isMessageFetching = true
  const first = active.messages[0]

  let query = active.ref.orderByKey()

  if (first) {
    query = query.endAt(first.id)
  }

  const snapshot = await query
    .limitToLast(limit)
    .get()

  active.addMessages({ snapshot, reverse: true })

  active.isMessageFetching = false

  if (first) stopAtCurrentScroll()
  mutate()
  if (!first) toScrollBottom()
}
