export default function Preview({ message }) {
  const [percentage, setPercentage] = useState(message.percentage)

  useEffect(() => {
    const interval = setInterval(() => {
      if (percentage === 1) {
        clearInterval(interval)
        return
      }
      setPercentage(message.percentage)
    }, 200)
    return () => clearInterval(interval)
  })

  return (
    <div className='relative' style={ message.style }>
      <C.Image
        src={ message.url }
        className='w-[100%] h-[100%]'
      />
      <div className='absolute inset-0'>
        <div className='absolute inset-0 bg-white opacity-70' />
        <div className='absolute top-1/2 w-full px-8 -translate-y-1/2'>
          <C.ProgressBar percentage={ percentage } />
        </div>
      </div>
    </div>
  )
}
