import requestSendMessage from 'request/core-messenger/send-message'
import { toScrollBottom } from './scroll-messages-bar'
import requestMetadata from 'request/core-messenger/metadata'
import chatroomMatch from 'request/core-messenger/chatroom-match'

export default async function sendMessage({ message, params, user, active, mutate }) {
  const sendAt = dayjs()
  const record = {
    isRead: false,
    timeStamp: sendAt.unix(),
    senderId: user.id,
    message,
    ...params
  }
  active.isSending = true

  const url = message.urls()[0]

  if (url) {
    const previewMessage = active.messageInit({
      record,
      key: uuid()
    })

    active.messages.push(previewMessage)
    mutate()
    toScrollBottom()
    try {
      const info = await requestMetadata({ url })
      record.linkData = {
        desc: info.description || '',
        host: info.url,
        imageUrl: info.image,
        siteName: info.title,
        title: info.title,
        url: info.url
      }
    } catch {}
    active.messages = active.messages.filter(m => m !== previewMessage)
  }

  await active.ref.push(record)

  active.isSending = false
  active.sendingDic = {}

  mutate()
  toScrollBottom()

  requestSendMessage({
    receiverId: active.user.id,
    message
  })

  checkMatch(active, user)
}

const checkMatch = (room, user) => {
  if (room.isSentMatchLog) return
  room.isSentMatchLog = true
  if (room.user.isServiceAccount) mkt.contactUs()
  if (!!user.stylist) return
  if (!room.user.stylist?.show) return
  chatroomMatch({ chatmateId: room.user.id })
  mkt.messageStylist(room.user.id)
}
