import { toScrollBottom } from './scroll-messages-bar'

export default function uploadImages({ files, active, user, mutate }) {
  for (let i = 0; i < files.length; i++) {
    const sendImage = async() => {
      const key = uuid()
      const { width, height, string } = await h.imageToString(files[i])
      const message = active.messageInit({
        key,
        record: {
          isPreview: true,
          senderId: user.id,
          string,
          width,
          height
        }
      })

      active.messages.push(message)

      const path = `${active.user.id}/${uuid()}.jpg`
      const task = store.plugin.firebase.storage()
        .ref()
        .child(path)
        .putString(
          string.split('base64,')[1],
          'base64',
          { contentType: 'image/jpg' }
        )

      task.then(snapshot => {
        active.messages = active.messages.filter(m => m !== message)
        active.sendMessage({
          message: 'Sent a photo',
          width,
          height,
          photoURL: snapshot.ref.toString()
        })
      })

      task.on('state_changed', snapshot => {
        const percentage = Math.min(snapshot.bytesTransferred / snapshot.totalBytes, 1)
        message.percentage = percentage
      })

      mutate()
      toScrollBottom()
    }
    sendImage()
  }
}
