let dic = {}

export default function useBlock(userId) {
  // eslint-disable-next-line no-unused-vars
  const { data, mutate } = useSWR('hooks/block', uuid)
  const t = useI18n()
  const { user, key } = useCurrent()

  const isBlocked = !!dic[userId]
  const blockActionWrapper = action => {
    if (isBlocked){
      $alert(t('error:user.notAllowBooking'), 'error')
      return
    }
    action()
  }
  useEffect(() => {
    const fetchDic = () => {
      if (!user) return {}
      return user.blockedUserIds.trueDic()
    }
    dic = fetchDic()
    mutate()
  }, [key])

  return {
    isBlocked,
    blockActionWrapper
  }
}
