const template = `
  query Chatrooms($limit: Int!, $offset: Int!) {
    chatrooms(limit: $limit, offset: $offset) {
      totalUnread
      chatrooms {
        chatmateUser {
          id
          name
          avatar
        }
        lastByOwner
        lastMessage
        lastUpdatedAt
        unread
      }
    }
  }
`

export default async function chatrooms({ limit, offset }) {
  const { chatrooms } = await request(template, { limit, offset })
  return chatrooms
}
