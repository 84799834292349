import { range } from 'lodash-es'

export default function MainHeader() {
  const { togglePanel, active, setActive, showUserId } = useCtx('coreMessenger')
  const t = useI18n()

  const leftStyle = () => {
    const basic = 'transition-all absolute  -translate-x-1/2'

    if (!active) return cn(basic, 'opacity-100 left-1/2')
    return cn(basic, 'opacity-0 left-1/4 pointer-events-none')
  }

  const rightStyle = () => {
    const basic = 'transition-all absolute text-left max-w-[76%]'
    if (active) return cn(basic, 'opacity-100 left-[3rem]')
    return cn(basic, 'opacity-0 left-[5rem] pointer-events-none')
  }

  return (
    <div className='text-center p-2 shadow-md flex items-center justify-between opacity-95 absolute w-full bg-white top-0'>
      <div
        className='text-gray-400 w-[2rem] flex justify-center'
      >
        <C.ButtonIcon
          className={ cn({ hidden: !active }) }
          onClick={ () => setActive(null) }
        >
          <Icon.ChevronLeft/>
        </C.ButtonIcon>
      </div>

      <div className={ leftStyle() }>{ t('common:messenger.message') }</div>
      <div className={ rightStyle() }
      >
        { renderRightTitle({ active, showUserId }) }
      </div>

      <C.ButtonIcon
        className='text-gray-400 w-[2rem] flex justify-center'
        onClick={ togglePanel }
      >
        <Icon.Close />
      </C.ButtonIcon>
    </div>
  )
}

const renderRightTitle = ({ active, showUserId }) => {
  if (!active) return

  const renderName = () => {
    if (active.isMateTyping()) return <IsTyping />
    return <div className='line-clamp-1 '>{ active.user.name }</div>
  }

  const renderStudioAddress = () => {
    if (!active.user.studio) {
      if (!showUserId) return
      return <div className='line-clamp-1 text-xs text-gray-600'>ID: { active.user.id }</div>
    }
    return <div className='line-clamp-1 text-xs text-gray-600'>{ active.user.studio.address }</div>
  }

  return (
    <div className={ cn('flex items-center justify-center space-x-1', { 'cursor-pointer': active.user.stylist?.show }) }
      onClick={ active.toUserPage }
    >
      <C.Avatar src={ active.user.avatar } size='8'/>
      <div className='py-1 pl-1'>
        <div>{ renderName () }</div>
        { renderStudioAddress() }
      </div>
    </div>
  )
}

const IsTyping = () => {
  const t = useI18n()
  const getDots = () => {
    const count = (Math.round((dayjs() % 2500) / 500))
    return range(count).map(() => '.').join('')
  }
  const [dot, setDot] = useState(getDots())
  useEffect(() => {
    const interval = setInterval(() => {
      setDot(getDots())
    }, 500)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className='text-gray-600'>{ t('common:messenger.isTyping', { dot }) }</div>
  )
}
