export default function MainSelectUser() {
  const {
    active,
    setActive,
    rooms,
    isChatroomFetching,
    fetchChatrooms
  } = useCtx('coreMessenger')

  const t = useI18n()
  if (active) return null

  return (
    <div className='relative w-full h-full'>
      <div className='w-full h-full overflow-scroll'
        onScroll= { infinite(fetchChatrooms) }
      >
        <div
          className='pt-[4rem]'
        >
          { rooms.map(room => (
            <Row
              key={ room.id }
              room={ room }
              onClick={ () => { setActive(room) } }
              t={ t }
            />
          ))}
          { isChatroomFetching && <C.Loading className='p-3' />}
        </div>
      </div>
      <C.Blur />
    </div>
  )
}

const Row = ({ room, onClick, t }) => (
  <div
    className='p-5 sm:p-2 flex items-center cursor-pointer hover:bg-gray-50 h-[4.5rem]'
    onClick={ onClick }
  >
    <C.Image
      src={ room.user.avatar }
      className='h-11 w-11 rounded-full mr-4  ml-1 shrink-0'
    />
    <div className='flex-1'>
      <div className='flex items-start'>
        <div className='flex-1 text-sm font-medium'>{ room.user.name }</div>
        <div className='text-[0.7rem] text-gray-400'>
          <C.FromNow time={ room.lastUpdatedAt } />
        </div>
      </div>
      <div className='flex justify-between'>
        <div className='line-clamp-2 text-xs text-gray-500 break-all'>
          { room.lastByOwner ? t('common:messenger.me') : '' }
          { room.lastMessage }
        </div>
        <div className='w-[2.3rem] shrink-0 text-right'>
          <C.Badge count={ room.unReadCount } />
        </div>
      </div>
    </div>
  </div>
)
