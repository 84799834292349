import requestUnSendMessage from 'request/core-messenger/un-send-message'

export default async function unSendMessage({ message, user, active, mutate, t }) {
  if (message.isPreview) return
  if (!confirm(t('common:messenger.unSend'))) return

  const lastMessageResolver = () => {
    let lastMsg = null
    for (let i = active.messages.length - 1; i >= 0; i--) {
      const m = active.messages[i]
      if (m.unSend) continue
      if (m.isPreview) continue
      lastMsg = m
      break
    }
    if (!lastMsg) return {}
    if (lastMsg.id !== message.id) return {}

    let prevMsg = null
    for (let i = active.messages.length - 1; i >= 0; i--) {
      const m = active.messages[i]
      if (m.unSend) continue
      if (m.isPreview) continue
      if (m.id === message.id) continue
      prevMsg = m
      break
    }
    if (!prevMsg) return { newLastMessage: '', lastByOwner: true, timeStamp: dayjs().unix() }

    const newLastMessage = prevMsg.type == 'photo' ? t('common:messenger.sendPhoto') : prevMsg.message
    const lastByOwner = prevMsg.senderId == user.id
    const timeStamp = prevMsg.timeStamp
    return { newLastMessage, lastByOwner, timeStamp }
  }

  const lastMessage = lastMessageResolver()

  await requestUnSendMessage({
    messageKey: message.id,
    senderId: user.id,
    receiverId: active.user.id,
    ...lastMessage
  })

  message.unSend = true
  mutate()
}
