import Text from './text'
import Preview from './preview'
import Photo from './photo'

export default function MessageRender({ message, unSendMessage }) {
  const { isSender, isPhoto, unSend } = message
  const { active } = useCtx('coreMessenger')
  const t = useI18n()

  if (unSend) return ''

  const color = () => {
    if (isPhoto) return
    if (isSender) return 'border border-gray-400'
    return 'bg-gray-100 border border-gray-100'
  }

  const renderAvatar = () => {
    if (isSender) return
    return (
      <C.Avatar
        onClick={ active.toUserPage }
        src={ active.user.avatar }
        className={ cn('mr-2  shrink-0', { 'cursor-pointer': active.user.stylist?.show }) }
        size='7'
      />
    )
  }

  const fetchComponent = () => {
    switch (message.type) {
      case 'preview':
        return Preview
      case 'photo':
        return Photo
      default:
        return Text
    }
  }

  const Component = fetchComponent()

  const renderLeftInfo = () => {
    if (!isSender) return
    const renderTimeStatus = () => {
      if (message.isSending()) return t('common:messenger.isSending')
      return message.sendAt.format('LT')
    }

    const renderMoreButton = () => {
      if (message.bookingInfo) return
      return (
        <div onClick={ unSendMessage } className='cursor-pointer px-2 opacity-0 group-hover:opacity-100'>
          <Icon.Dot className='scale-90'/>
        </div>
      )
    }

    return (
      <div className='text-[0.6rem] px-1 text-gray-500 select-none flex items-center'>
        { renderMoreButton() }
        <div>
          <div className={ cn('text-right', { hidden: !message.isRead() }) }>
            { t('common:messenger.isRead') }
          </div>
          <div>{ renderTimeStatus() }</div>
        </div>
      </div>
    )
  }

  const renderRightInfo = () => {
    if (isSender) return
    return (
      <div className='text-[0.6rem] px-1 text-gray-500 select-none'>
        <div>{ message.sendAt.format('LT') }</div>
      </div>
    )
  }

  const padding = () => {
    if (isPhoto) return
    return 'px-3 p-[0.4rem]'
  }

  const renderSitePreview = () => {
    if (!message.linkData) return
    const { title, desc, imageUrl, url } = message.linkData
    return (
      <C.Link href={ url } blank noUnderline>
        <div className='border-l-2 border-gray-500 pl-[0.5rem] py-[0.1rem] my-[0.6rem] flex'>
          <div className='flex-1'>
            <div className='line-clamp-1 text-[0.8rem] font-medium'>{ title }</div>
            <div className='line-clamp-2 text-[0.75rem] py-1 leading-4 max-h-[2.3rem]'>{ desc }</div>
          </div>
          <div className='shrink-0 pl-2'>
            <C.Image
              src={ imageUrl }
              className='w-14 h-14 bg-gray-50 rounded-[2px]'
            />
          </div>
        </div>
      </C.Link>
    )
  }

  const LocalButton = ({ children, ...props }) => (
    <div className='flex justify-center'>
      <div className={ cn('text-center p-1 py-2 px-4 rounded-[2px] text-xs cursor-pointer min-w-[13rem] w-full', buttonStyle()) }
        { ...props }
      >
        {children}
      </div>
    </div>
  )
  const buttonStyle = () => {
    if (isSender) return 'border border-black hover:bg-gray-100'
    return 'bg-black text-white hover:bg-gray-700'
  }

  const renderBookingButton = () => {
    if (!message.bookingInfo) return
    const { openBooking, text } = message.bookingInfo
    return (
      <LocalButton onClick={ () => openBooking() }>
        { text }
      </LocalButton>
    )
  }

  const renderHowToPay = () => {
    if (!message.showHotToPayBtn) return
    return (
      <LocalButton>
        <C.Link
          href={ h.path.full.howToPay() }
          blank
          noColor
          noUnderline
        >
          { t('common:messenger.button.howToPay')}
        </C.Link>
      </LocalButton>
    )
  }

  return (
    <div className={ cn('group py-[0.3rem] flex items-start', { 'justify-end': isSender }) }>
      { renderAvatar() }
      <div className='flex items-end max-w-[18rem] overflow-hidden'>
        { renderLeftInfo() }
        <div className={ cn('inline-block rounded-[1rem] break-all relative text-sm max-w-[16rem] ', padding(), color()) }>
          <Component message={ message } />
          { renderSitePreview() }
          <div className={ cn('space-y-2 my-2', { hidden: !message.anyButton }) }>
            { renderBookingButton() }
            { renderHowToPay() }
          </div>
        </div>
        { renderRightInfo() }
      </div>
    </div>
  )
}
