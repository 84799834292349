import MessageRender from './message-render'
import Textarea from './textarea'
import { animateToBottom } from 'store/core-messenger/action/scroll-messages-bar'
import useBlock from 'hooks/block'

export default function MainMessage() {
  const [bottomBtnVisible, setBottomBtnVisible] = useState(false)
  const [isDrag, setDrag] = useState(false)
  const [isDragAllowd, setDragAllowed] = useState(true)
  const { active } = useCtx('coreMessenger')
  const t = useI18n()
  const { blockActionWrapper } = useBlock(active.user.id)

  const dateDic = {}

  const renderDate = message => {
    const { sendAt, id } = message
    const key = sendAt.ymd()
    dateDic[key] ||= id
    if (dateDic[key] !== id) return

    const text = () => {
      const today = dayjs()
      if (key === today.ymd()) return t('common:messenger.today')
      const diff = today.diff(sendAt, 'day')
      if (diff > 30) return sendAt.ymdw()
      return sendAt.mdw()
    }

    return (
      <div className='flex justify-center my-1 select-none'>
        <div className='text-center text-[0.6rem] py-[0.1rem]  px-2 rounded-[0.5rem] bg-gray-100'>{ text() }</div>
      </div>
    )
  }

  const renderUnsend = message => {
    const { isSender, unSend } = message
    if (!unSend) return
    const text = () => {
      if (isSender) return t('common:messenger.unSendBySender')
      return t('common:messenger.unSendByReceiver', { name: active.user.name })
    }

    return (
      <div className='flex justify-center my-2 select-none'>
        <div className='text-center text-[0.6rem] py-[0.3rem] px-3 rounded-[0.5rem] bg-gray-100 text-gray-500'>{ text() }</div>
      </div>
    )
  }

  const renderMessages = () => {
    if (active.isMessageFinish && !active.messages.length) return <div className='text-gray-600 text-sm text-center py-6 select-none'>{ t('common:messenger.sayHi', { name: active.user.name }) }</div>

    return active.messages.map(message => (
      <div
        key={ message.id }
      >
        { renderDate(message) }
        { renderUnsend(message) }
        <MessageRender
          message={ message }
          unSendMessage={ () => active.unSendMessage({ message }) }
        />
      </div>
    ))
  }
  const infiniteLoader = infinite(() => active && active.fetchMessages(), true)

  const onScroll = event => {
    const { clientHeight, scrollTop, scrollHeight } = event.target
    infiniteLoader(event)
    setBottomBtnVisible(scrollHeight - scrollTop > clientHeight * 1.5)
    if (scrollHeight === scrollTop + clientHeight) active.scrollAtBottom()
  }

  const onDrop = e => {
    e.preventDefault()
    setDrag(false)
    if (!isDragAllowd) return
    blockActionWrapper(() => active.uploadImages(e.dataTransfer.files))
  }

  const onDragLeave = e => {
    e.preventDefault()
    setDrag(false)
  }

  const onDragOver = e => {
    e.preventDefault()
    if (isDrag) return
    setDrag(true)
    const files = [
      ...e?.dataTransfer?.items,
      ...e?.dataTransfer?.files
    ]
    for (let i = 0;i < files.length;i++) {
      if (files[i].type.includes('image')) continue
      setDragAllowed(false)
      return
    }
    setDragAllowed(true)
  }

  const opacity = () => {
    if (isDrag) return 'opacity-100'
    return 'opacity-0'
  }

  const animateToBottomBtnVisible = () => {
    if (active.mateNewText) return 'hidden'
    if (bottomBtnVisible) return ''
    return 'hidden'
  }

  return (
    <div className='w-full h-full relative'
      onDragLeave={ onDragLeave }
      onDragOver={ onDragOver }
      onDrop={ onDrop }
    >
      <div className='h-full w-full relative'>
        <div className={ cn('transition-all absolute inset-0 z-50 flex items-center items-center justify-center pointer-events-none  bg-white/50 backdrop-blur', opacity()) }
        >
          <div className='font-bold'>
            {
              isDragAllowd
                ? t('common:messenger.dropzone.imageWillUpload')
                : t('common:messenger.dropzone.imageOnly')
            }
          </div>
        </div>
        <div
          className='h-full w-full py-16 px-3 overflow-scroll message-scroll'
          onScroll={ onScroll }
        >
          { renderMessages() }
        </div>
        <C.Blur className='pt-[2rem]' />
        <div className='absolute bottom-3 sm:bottom-2 w-[96%] left-[2%]'>
          <div className={ cn('absolute top-[-3rem] right-0 bg-white shadow p-[0.4rem] text-gray-500 rounded opacity-90 cursor-pointer hover:opacity-95', animateToBottomBtnVisible()) }
            onClick={ animateToBottom }
          >
            <Icon.ArrowDown />
          </div>
          <div className='absolute inset-0 rounded-[1.5rem] backdrop-blur-md bg-gray-100/70'></div>
          <Textarea />
        </div>
      </div>
    </div>
  )
}
