import TextField from '@mui/material/TextField'
import useBlock from 'hooks/block'

export default function Textarea() {
  const { active } = useCtx('coreMessenger')
  const { isBlocked, blockActionWrapper } = useBlock(active.user.id)
  const t = useI18n()
  const { isAdmin } = useCurrent()

  const ref = useRef(null)
  const [text, setText] = useState('')
  const [sendAt, setSendAt] = useState(null)
  const sendable = !!text.trim()
  const textRef = useRef(null)

  const onSend = () => {
    if (!sendable) return
    blockActionWrapper(() => {
      let message = text.trim()
      if (active.autoMessage) {
        message = `${active.autoMessage}\n\n${message}`
        active.autoMessage = null
      }
      active.sendMessage({ message })
    })
    setText('')
    setSendAt(dayjs())
    textRef.current.focus()
    active.setTyping(false)
  }

  const onTextChange = e => {
    if (sendAt && ((dayjs() - sendAt) < 200)) return
    setText(e.target.value)
    active.setTyping(!!e.target.value)
  }

  const onKeyDown = e => {
    const isSend = () => {
      if (h.isMobile()) return false
      if (e.keyCode !== 13) return false
      if (e.shiftKey) return false
      return true
    }
    if (isSend()) return onSend()
  }

  const selectPhotoClick = () => {
    blockActionWrapper(() => ref.current.click())
  }

  const onFileChanged = e => {
    active.uploadImages(e.target.files)
    e.target.value = null
  }

  const saveMessageCallback = message => {
    setText(message)
  }
  const onSaveMessageClick = () => h.openSaveMessages({ callback: saveMessageCallback })

  const renderSaveMessageBtn = () => {
    if (!isAdmin) return
    return (
      <div onClick={ onSaveMessageClick } className='cursor-pointer'>
        <Icon.Chat className='scale-90 text-gray-600 hover:text-gray-800'/>
      </div>)
  }

  const renderFloatBtn = () => {
    if (text.length) return (
      <div
        className={ cn('text-sm hover:font-medium cursor-pointer', {
          'text-gray-400': !sendable,
          'font-bold': sendable
        }) }
        onClick={ onSend }
      >{ t('common:messenger.send') }</div>
    )

    return (
      <div className='flex space-x-2'>
        { renderSaveMessageBtn() }
        <Icon.PaperClip
          onClick={ selectPhotoClick }
          className='scale-75 cursor-pointer text-gray-600 hover:text-gray-800'
        />
      </div>
    )
  }

  return (
    <div className='w-full relative select-none'>
      <div
        className={ cn('absolute top-[-0.7rem] left-0 bg-white opacity-95 w-full -translate-y-full cursor-pointer shadow border border-gray-100', { hidden: !active.mateNewText }) }
        onClick={ () => active.toMateNewText() }
      >
        <div className='p-2 text-gray-600 flex '>
          <div className='truncate max-w-[5rem] shrink-0'>{ active.user.name }</div>
          <div className='pr-2 shrink-0'>:</div>
          <div className='line-clamp-1 break-all max-h-[3.6rem] '>
            { active.mateNewText }
          </div>
        </div>
      </div>
      <TextField
        multiline
        maxRows='3'
        autoFocus
        inputRef={ textRef }
        className='main-message-textarea'
        placeholder={ t('common:messenger.textPlaceholder') }
        value={ text }
        onKeyDown={ onKeyDown }
        onChange={ onTextChange }
        disabled={ isBlocked }
      />
      <div className={ 'absolute top-1/2 right-4 -translate-y-1/2' }
      >
        <input
          type='file'
          ref={ ref }
          className='hidden'
          accept='image/*'
          multiple='multiple'
          onChange={ onFileChanged }
        />
        { renderFloatBtn() }
      </div>
      <style global jsx>{`
      .main-message-textarea {
        width: 100%;
        .MuiOutlinedInput-root {
          padding: 1rem;
          padding-right: 3rem;
          width: 100%;
          line-height: 1.2rem;
        }
        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      }
    `}</style>
    </div>
  )
}
