export default function BackgroundCover() {
  const { togglePanel, panelVisible, zIndex } = useCtx('coreMessenger')

  return (
    <div className={ cn('fixed inset-0 bg-black opacity-5 hidden sm:block', { 'sm:hidden': !panelVisible }) }
      onClick={ togglePanel }
      style={ { zIndex } }
    />
  )
}
