export default function chatroomInfoUpdate({ record, fetchRoomId, state, mutate, chatroomInit }) {
  const mate = record.chatmateUser
  const id = fetchRoomId(mate.id)
  const room = state.roomDic[id] || chatroomInit({ record })

  room.lastUpdatedAt = dayjs()
  room.lastMessage = record.lastMessage
  room.lastByOwner = record.lastByOwner
  room.unReadCount = record.unread

  state.totalUnread = record.totalUnread

  state.rooms = [
    room,
    ...state.rooms.filter(current => current.id !== room.id)
  ]

  mutate()

  if (!state.active) return notifyReceiver(room, record)
  if (room.id !== state.active.id) return notifyReceiver(room, record)

  room.read()
}

const notifyReceiver = (room, record) => {
  if (room.lastByOwner) return
  if (!record.message) return
  h.audio.play('messageReceived')
  $alert(record.message)
}