const fetchDom = () => {
  const doms = document.getElementsByClassName('message-scroll')
  if (!doms.length) return null
  if (doms[0].scrollHeight) return doms[0]
  return doms[1]
}

const toScrollBottom = () => {
  const dom = fetchDom()
  setTimeout(() => {
    dom.scrollTop = dom.scrollHeight
  })
}

const stopAtCurrentScroll = () => {
  const dom = fetchDom()
  const diffToBottom = dom.scrollHeight - dom.scrollTop
  setTimeout(() => {
    dom.scrollTop = dom.scrollHeight - diffToBottom
  }, 0)
}

const animateToBottom = () => {
  const dom = fetchDom()
  animate(dom, dom.scrollHeight)
}

const nearScrollBottom = () => {
  const dom = fetchDom()
  return dom.scrollHeight - dom.scrollTop < dom.clientHeight + 50
}

export {
  animateToBottom,
  nearScrollBottom,
  stopAtCurrentScroll,
  toScrollBottom
}
