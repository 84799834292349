const template = `
  mutation ChatroomRead($chatmateId: ID!) {
    chatroomRead(chatmateId: $chatmateId) {
      totalUnread
    }
  }
`

export default async function chatroomRead({ chatmateId }) {
  await request(template, { chatmateId })
}
